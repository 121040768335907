import { CareerSiteSettings } from 'api-clients/monolith';
import cx from 'classnames';
import { AppContext } from 'contexts/app-context';
import { ChatIcon } from 'icons/chat-icon';
import React, { useContext } from 'react';

import { Skeleton } from 'components/ui/skeleton';

import { MasterSearch } from './master-search';

interface FaiWidget {
  dispatchMessage: (payload: {
    name: string;
    args?: { message?: string };
  }) => void;
}

declare global {
  interface Window {
    faiWidget?: FaiWidget;
  }
}

interface HomeSearchProps {
  scrollToJobs: () => void;
  brandSetting: CareerSiteSettings;
}
export function HomeSearch({ scrollToJobs, brandSetting }: HomeSearchProps) {
  const { brandName, isCmsAccount } = useContext(AppContext);

  const postMessageToWidget = (message?: string) => {
    if (!window || !window.faiWidget) {
      return;
    }

    window.faiWidget.dispatchMessage({
      name: 'x-fai-window-maximize',
    });

    if (message) {
      window.faiWidget?.dispatchMessage({
        name: 'x-fai-window-post-message',
        args: { message },
      });
    }
  };

  const handleTagClick = (content: string) => {
    postMessageToWidget(content);
  };

  return (
    <div
      className={cx(
        'flex h-full flex-col items-center justify-center gap-y-6 px-4 py-4',
        {
          'via-primary-400 to-primary-500 from-primary-500 bg-gradient-to-br from-30% via-70% to-100% py-10':
            !isCmsAccount,
        },
      )}
    >
      <h1
        className={cx('text-center text-3xl font-medium', {
          'text-primary-foreground mb-4': !isCmsAccount,
        })}
      >
        Search openings at{' '}
        <span className="whitespace-nowrap">{brandName}</span>
      </h1>

      <MasterSearch scrollToJobs={scrollToJobs} brandSetting={brandSetting} />

      <div className="flex flex-wrap items-center justify-center gap-2 md:w-[70%] lg:w-[50%]">
        {brandSetting?.show_chat_agent_widget &&
          brandSetting?.show_faq_chatbot_by_default && (
            <>
              {brandSetting?.prefilled_faq_chatbot_questions?.map(message => (
                <button
                  key={message}
                  type="button"
                  onClick={() => handleTagClick(message)}
                  className={cx(
                    'h-[32px] rounded-2xl px-3 py-2 text-[13px] leading-4',
                    { 'bg-primary-50': !isCmsAccount },
                  )}
                >
                  {message}
                </button>
              ))}
              <button
                type="button"
                onClick={() => postMessageToWidget()}
                className={cx(
                  'bg-primary-foreground text-14 font-500 flex h-[40px] items-center gap-1 rounded-[40px] px-4 py-2 leading-5',
                  { 'text-primary': !isCmsAccount },
                )}
              >
                <ChatIcon />
                <p>Chat with us</p>
              </button>
            </>
          )}
      </div>
    </div>
  );
}

HomeSearch.Skeleton = function HomeSearchSkeleton() {
  return <Skeleton className="h-full w-full" />;
};
