import { CareerSiteSettings } from 'api-clients/monolith';
import cx from 'classnames';
import { AppContext } from 'contexts/app-context';
import { SearchIcon } from 'icons/search-icon';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { SearchPopover } from './search-popover';
interface MasterSearchProps {
  scrollToJobs: () => void;
  brandSetting: CareerSiteSettings;
}

export function MasterSearch({
  scrollToJobs,
  brandSetting,
}: MasterSearchProps) {
  const listRef = useRef<HTMLDivElement>(null);

  const { setJobSearchQuery, resetFilters, isCmsAccount } =
    useContext(AppContext);

  const [openPopover, setOpenPopover] = useState(false);
  const [searchString, setSearchString] = useState('');

  const handleOpenPopover = () => {
    setOpenPopover(true);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (listRef.current && !listRef.current.contains(event.target as Node)) {
      setOpenPopover(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (value: string) => {
    setSearchString(value);
  };

  const resetSearch = () => {
    setSearchString('');
  };

  const searchJobs = () => {
    resetFilters();
    setJobSearchQuery(searchString);
    setOpenPopover(false);
    resetSearch();
  };

  const handleJobSearch = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      searchJobs();
    }
  };

  const handleGoClick = () => {
    searchJobs();
  };

  const searchPlaceHolder = brandSetting.is_locations_disabled
    ? 'Search by opening name'
    : 'Search by opening name or location';

  return (
    <div className="relative w-full md:w-[560px]" ref={listRef}>
      <div className="flex flex-row items-center gap-4">
        <button
          type="button"
          onClick={handleOpenPopover}
          className="flex h-[48px] w-full items-center gap-3 rounded-[5px] border bg-white px-4 py-3"
        >
          <SearchIcon />
          <input
            placeholder={searchPlaceHolder}
            onChange={e => handleInputChange(e.target.value)}
            onKeyDown={handleJobSearch}
            className="flex-1 border-none outline-none"
            value={searchString}
          />
        </button>
        <button
          type="button"
          onClick={handleGoClick}
          className={cx(
            'text-primary bg-primary-foreground text-16 font-500 h-[48px] rounded-[48px] px-4 py-2 leading-5 md:hidden',
            { '!bg-primary-500 !text-white': isCmsAccount },
          )}
        >
          Go
        </button>
      </div>
      {openPopover && (
        <SearchPopover
          searchString={searchString}
          setOpenPopover={setOpenPopover}
          scrollToJobs={scrollToJobs}
          resetSearch={resetSearch}
        />
      )}
    </div>
  );
}
