import { AppContext } from 'contexts/app-context';
import { ChevronLeft } from 'lucide-react';
import React, { useContext, useMemo } from 'react';

import { JobsList } from 'components/jobs-list';
import { Skeleton } from 'components/ui/skeleton';
import { useJobs } from 'hooks/useJobs';

import { Pagination, PaginationSkeleton } from './pagination';

export function JobsSectionSkeleton() {
  const { isMobile } = useContext(AppContext);
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center justify-between">
        <Skeleton className="h-8 w-full md:w-1/3" />
        {!isMobile && <Skeleton className="h-8 w-[325px]" />}
      </div>
      <JobsList.SkeletonGroup />
      <PaginationSkeleton />
    </div>
  );
}

export function JobsSection() {
  const {
    location,
    brandName,
    isFetchingJobs,
    jobSearchQuery,
    experienceLevelName,
    page,
    setPage,
    isMobile,
    position,
    resetFilters,
    setIsJobsFromCurrentLocation,
    activeFilters,
    isCmsAccount,
  } = useContext(AppContext);

  const { jobs, paginationData, isCurrentLocation, currentCity } = useJobs();
  const categoryName = activeFilters.category?.label;
  const areJobsAvailable = jobs.length > 0;
  const positionName = position.value;
  const isCmsFiltersDirty =
    activeFilters.location.key !== 'current_location' ||
    activeFilters.position.key !== 'all' ||
    activeFilters.department.key !== 'all';

  const { title, subTitle } = useMemo(() => {
    const selectedLocation = `(${currentCity ?? location.value ?? ''})`;

    if (isCmsAccount && activeFilters.location.key === 'all')
      return {
        title: 'All Available Openings',
        subTitle: `See all available openings from ${brandName} below or search openings in a different location.`,
      };

    if (
      isCmsAccount &&
      activeFilters.location.key !== 'all' &&
      activeFilters.location.key !== 'current_location' &&
      areJobsAvailable
    )
      return {
        title: `Openings Near (${activeFilters.location.label})`,
        subTitle: null,
      };

    if (isCmsAccount && areJobsAvailable && isCmsFiltersDirty)
      return {
        title: `Available Openings`,
        subTitle: null,
      };

    if (isCmsAccount && !areJobsAvailable && isCmsFiltersDirty)
      return {
        title: `No Openings found near (${activeFilters.location.label})`,
        subTitle: null,
      };

    if (jobSearchQuery)
      return {
        title: `Search results for "${jobSearchQuery}"`,
        subTitle: null,
      };

    if (categoryName && categoryName !== 'Any' && areJobsAvailable)
      return {
        title: `Openings in ${categoryName}`,
        subTitle: null,
      };

    if (experienceLevelName && areJobsAvailable)
      return {
        title: `Openings in ${experienceLevelName}`,
        subTitle: null,
      };

    if (positionName && areJobsAvailable)
      return {
        title: `Openings in ${positionName}`,
        subTitle: null,
      };

    if (
      areJobsAvailable &&
      isCurrentLocation &&
      (currentCity || location.value)
    )
      return {
        title: `Openings Near You ${selectedLocation}`,
        subTitle: null,
      };

    return {
      title: `No Nearby Openings found ${selectedLocation}`,
      subTitle: `See all available openings from ${brandName} below or search openings in a different location.`,
    };
  }, [
    currentCity,
    location.value,
    jobSearchQuery,
    categoryName,
    areJobsAvailable,
    experienceLevelName,
    positionName,
    isCurrentLocation,
    brandName,
    isCmsAccount,
    isCmsFiltersDirty,
    activeFilters,
  ]);

  const handleBackButtonClick = () => {
    resetFilters();
    setIsJobsFromCurrentLocation(true);
  };

  if (
    (!isMobile && isFetchingJobs) ||
    (isMobile && isFetchingJobs && page === 1)
  ) {
    return <JobsSectionSkeleton />;
  }

  return (
    <div className="flex flex-col gap-3">
      {(categoryName ||
        experienceLevelName ||
        positionName ||
        location.id ||
        jobSearchQuery) && (
        <button
          type="button"
          onClick={handleBackButtonClick}
          className="flex text-left"
        >
          <ChevronLeft />
          Back
        </button>
      )}

      <div className="flex flex-row items-center justify-between">
        <h2 className="text-24 font-500 leading-8">{title}</h2>
      </div>
      {subTitle && <p className="text-18 font-400 leading-6">{subTitle}</p>}

      <JobsList jobs={jobs} />

      <Pagination
        currentPage={page}
        lastPage={paginationData?.total_pages ?? 1}
        setPage={setPage}
        jobsCount={paginationData?.total_count ?? 0}
      />
    </div>
  );
}
